/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

import styles from "./index.module.scss";

const Navbar = () => {
	const { t } = useTranslation();
	const locale = useSelector(state => state.locale.value.lang)

	return (
		<div className={styles.container}>
			<ul className={styles.links}>
				<li>
					<NavLink to={`/${locale}/`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.main")}
					</NavLink>
				</li>
{/* 
				<li>
					<NavLink to={`${locale}/marble`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.marble")}
					</NavLink>
				</li> */}


				<li>
					<NavLink to={`${locale}/marble/egypt`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.marble.main")}
						<IoIosArrowDown />
					</NavLink>
					<ul className={`${styles.subMenu} ${styles[locale]}`}>
						<li>
							<NavLink to={`${locale}/marble/egypt`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
								{t("navbar.marble.egypt")}
							</NavLink>
						</li>
						<li>
							<NavLink to={`${locale}/marble/india`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
								{t("navbar.marble.india")}
							</NavLink>
						</li>
					</ul>
				</li>



				<li>
					<NavLink to={`${locale}/granite/egypt`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.granite.main")}
						<IoIosArrowDown />
					</NavLink>
					<ul className={`${styles.subMenu} ${styles[locale]}`}>
						<li>
							<NavLink to={`${locale}/granite/egypt`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
								{t("navbar.granite.egypt")}
							</NavLink>
						</li>
						<li>
							<NavLink to={`${locale}/granite/india`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
								{t("navbar.granite.india")}
							</NavLink>
						</li>
					</ul>
				</li>

				<li>
					<NavLink to={`${locale}/contact`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.contact")}
					</NavLink>
				</li>

			</ul>
		</div>
	);
};

export default Navbar;
