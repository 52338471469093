import React from 'react'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { FaFacebookF } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { TfiEmail } from "react-icons/tfi";
import { AiOutlineYoutube } from "react-icons/ai";

const SocailContainer = () => {

  const locale = useSelector(state => state.locale.value.lang)

  return (
    <div
      className={styles.container}
      style={{
        left: locale === 'ar' ? '0' : 'unset',
        right: locale === 'ar' ? 'unset' : '0',
      }}
    >
      <a href={`/${locale}/`}>
        <FaFacebookF />
      </a>
      <a href="https://wa.me/+201007025204" rel="noreferrer" target="_blank">
        <FaWhatsapp />
      </a>
      <a href={`/${locale}/`}>
        <GrInstagram />
      </a>
      <a href={`/${locale}/`}>
        <TfiEmail />
      </a>
      <a href={`/${locale}/`}>
        <AiOutlineYoutube />

      </a>
    </div>
  )
}

export default SocailContainer