import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import Loader from './components/compound/loader';

// lazy loading routes
const LazyHome = React.lazy(() => import('./pages/Home'))
const LazyMarble = React.lazy(() => import('./pages/Marble'))
const LazyGranite = React.lazy(() => import('./pages/Granite'))
const LazyMarbleDetails = React.lazy(() => import('./pages/MarbleDetails'))
const LazyGraniteDetails = React.lazy(() => import('./pages/GraniteDetails'))
const LazyContact = React.lazy(() => import('./pages/Contact'))


const RoutesContainer = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate replace={true} to="/ar/" />}></Route>
      <Route exact path='/:lang/' element={
        <Suspense fallback={<Loader />}>
          <LazyHome />
        </Suspense>
      }>
      </Route>
      <Route path='/:lang/marble' element={
        <Suspense fallback={<Loader />}>
          <Navigate replace to="/:lang/marble/egypt" />
        </Suspense>
      }>
      </Route>

      <Route path='/:lang/marble/:country' element={
        <Suspense fallback={<Loader />}>
          <LazyMarble />
        </Suspense>
      }>
      </Route>

      <Route path='/:lang/marble/details/:marbleId' element={
        <Suspense fallback={<Loader />}>
          <LazyMarbleDetails />
        </Suspense>}>
      </Route>



      <Route path='/:lang/granite' element={
        <Suspense fallback={<Loader />}>
          <Navigate replace to="/:lang/granite/egypt" />
        </Suspense>
      }>
      </Route>
      <Route path='/:lang/granite/:country' element={
        <Suspense fallback={<Loader />}>
          <LazyGranite />
        </Suspense>
      }>
      </Route>


      <Route path='/:lang/granite/details/:graniteId' element={
        <Suspense fallback={<Loader />}>
          <LazyGraniteDetails />
        </Suspense>}>
      </Route>


      <Route path='/:lang/contact' element={
        <Suspense fallback={<Loader />}>
          <LazyContact />
        </Suspense>
      }>
      </Route>
      {/* create 404 route */}
      <Route path='*' element={<Navigate replace to="/ar/" />}>
      </Route>
    </Routes>
  )
}

export default RoutesContainer