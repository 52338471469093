// redux , translation
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// scroll pakaage
import { animateScroll as scroll } from "react-scroll/modules"
// locale image
import email from '../../../assets/icons/email.png'
import locationIcon from '../../../assets/icons/location.png'
import logo from '../../../assets/icons/logo1.png'
import phone from '../../../assets/icons/phone.png'
import whatsapp from '../../../assets/icons/whatsapp.png'
// dispatch action
import { changeLocale } from '../../../redux/Features/locale'
// local styles
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.scss'


const Header = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const locale = useSelector(state => state.locale.value.lang)
  const [language, setLanguage] = useState(locale)
  const location = useLocation()
  const navigate = useNavigate()
  // handle change language
  const changeLanguage = (lang) => {
    if (lang === "ar") {
      setLanguage("ar")
      dispatch(changeLocale({ lang: "ar", dir: "rtl" }))
      navigate(`/${lang}${location.pathname.slice(3)}`)
    } else if (lang === "en") {
      setLanguage("en")
      navigate(`/${lang}${location.pathname.slice(3)}`)
      dispatch(changeLocale({ lang: "en", dir: "ltr" }))
    } else if (lang === "fr") {
      setLanguage("fr")
      navigate(`/${lang}${location.pathname.slice(3)}`)
      dispatch(changeLocale({ lang: "fr", dir: "ltr" }))
    }
  }

  return (
    <div className={styles.container}>
      {/* login component */}
      <a href="/" className={styles.logo} onClick={() => scroll.scrollToTop()}>
        <img src={logo} alt="logo" />
      </a>
      <div className={styles.camponyInfo}>
        <a className={styles.infoItem} href="https://goo.gl/maps/hauaW8WryxpJQ2t86" target="_blank" rel='noreferrer'>
          <img src={locationIcon} className={styles.icon} alt="" />
          <div className={styles.data}>
            <span className={styles.upper}>
              {t("header.location")}
            </span>
            <span className={styles.lower}>
              {t("header.locationText")}
            </span>
          </div>
        </a>
        <a className={styles.infoItem} href="https://wa.me/+201007025204" target="_blank" rel="noreferrer">
          <img src={whatsapp} className={styles.icon} alt="" />
          <div className={styles.data}>
            <span className={styles.upper}>
              {t("header.whatsapp")}
            </span>
            <span className={styles.lower}>
              01007025204
            </span>
          </div>
        </a>
        <div className={styles.infoItem}>
          <img src={phone} className={styles.icon} alt="" />
          <div className={styles.data}>
            <span className={styles.upper}>
              {t("header.phone")}
            </span>
            <span className={styles.lower}>
              01007025204
            </span>
          </div>
        </div>
        <a className={styles.infoItem} href="mailto:info@tawasolexport.com" target="_blank" rel="noreferrer">
          <img src={email} className={styles.icon} alt="" />
          <div className={styles.data}>
            <span className={styles.upper}>
              {t("header.email")}
            </span>
            <span className={styles.lower}>
              Info@tawasolexport.com
            </span>
          </div>
        </a>
      </div>
      {/* language component */}
      <div className={styles.languageBtns}>
        <span className={styles.langText}>{t("header.language")}</span>
        {/* change language value when select item */}
        <select className={styles.select} value={locale} onChange={(e) => changeLanguage(e.target.value)}>
          <option className={language === "ar" ? styles.active : undefined} value="ar">{t("header.arabic")}</option>
          <option className={language === "en" ? styles.active : undefined} value="en">{t("header.english")}</option>
          <option className={language === "fr" ? styles.active : undefined} value="fr">{t("header.french")}</option>
        </select>
      </div>
    </div>
  )
}

export default Header