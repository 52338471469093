import './index.scss'

const Loader = () => {
  return (
    <div className='loaderContainer'>
      <div className='loader'></div>
    </div>
  )
}

export default Loader